// Variables
@import "variables";


.submit-scan {
    background:$blue;
    .login-logo {
        .logo {
            width:150px;
            height:auto;
            display: block;
            margin: 0 auto;
        }
    }

}
.login-logo {

    img {
        max-width:100%;
        display: block;
        margin:0 auto;
        height:auto;
    }
}

.main-header {
    .logo {
        img {
            max-width:100px;
        }
    }
}

#dashboard-orders {
    td {
        vertical-align: middle;
        a {
            display: block;
        }
    }
}

.status-label {
    cursor:pointer;
}
.profile_orders {

    li {

        padding:10px 15px;
    }
}